import Box from "@mui/material/Box";
import { SxProps, Theme } from "@mui/material/styles";
import { type PerfumeIngredient } from "@/models";
import { useIngredientDiplayName } from "@/services";

export type PictureSize = "icon" | "thumbnail" | "large";
type AvailableIngredientPictureSize = 64 | 128 | 256 | 512;

const ingredientImagesSizesDensities: Record<PictureSize, AvailableIngredientPictureSize[]> = {
  icon: [64, 128, 256],
  thumbnail: [128, 256, 512],
  large: [512],
};

const ingredientImageName = (
  image: string,
  imageSize: AvailableIngredientPictureSize,
  extension: string,
) => `${image}-${imageSize}.${extension}`;

function ingredientSrcSet(
  image: string,
  imageSize: PictureSize,
  extension: string,
): { src: string; srcSet: string } {
  const sizes = ingredientImagesSizesDensities[imageSize];
  return {
    src: ingredientImageName(image, sizes[0], extension),
    srcSet: sizes
      .map((size, i) => `${ingredientImageName(image, size, extension)} ${i + 1}x`)
      .join(", "),
  };
}

export function IngredientPicture({
  ingredient,
  size,
  sx,
}: {
  ingredient: PerfumeIngredient;
  size: PictureSize;
  sx?: SxProps<Theme>;
}) {
  const avif = ingredientSrcSet(ingredient.icon, size, "avif");
  const png = ingredientSrcSet(ingredient.icon, size, "png");

  const sizePx = ingredientImagesSizesDensities[size][0];

  const ingredientDisplayName = useIngredientDiplayName();
  return (
    <Box component="picture">
      <source type="image/avif" src={avif.src} srcSet={avif.srcSet} />
      <Box
        component="img"
        sx={{
          // filter: "saturate(80%)",
          width: `${sizePx}px`,
          height: `${sizePx}px`,
          ...sx,
        }}
        src={png.src}
        srcSet={png.srcSet}
        alt={ingredientDisplayName(ingredient)}
        loading="lazy"
      />
    </Box>
  );
}
