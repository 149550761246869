import { ComponentCategory } from "@/models";
import Box from "@mui/material/Box";
import type { TFunction } from "i18next";
import { useTranslation } from "react-i18next";

function translatePerfumeCategoryTitle(
  t: TFunction<"translation">,
  category: ComponentCategory,
  args?: { quantity: number },
): string {
  const plural = (args?.quantity || 1) !== 1;

  switch (category) {
    case ComponentCategory.NOTE_DE_TETE:
      return t(plural ? "notesDeTete" : "noteDeTete");
    case ComponentCategory.NOTE_DE_COEUR:
      return t(plural ? "notesDeCoeur" : "noteDeCoeur");
    case ComponentCategory.NOTE_DE_FOND:
      return t(plural ? "notesDeFond" : "noteDeFond");
  }
}

function perfumeCategoryIcon(category: ComponentCategory): string {
  switch (category) {
    case ComponentCategory.NOTE_DE_TETE:
      return "/note-de-tete.png";
    case ComponentCategory.NOTE_DE_COEUR:
      return "/note-de-coeur.png";
    case ComponentCategory.NOTE_DE_FOND:
      return "/note-de-fond.png";
  }
}

export function PerfumeCategoryTitle({
  category,
  quantity,
  noIcon,
}: {
  category: ComponentCategory;
  quantity: number;
  noIcon?: boolean;
}) {
  const { t } = useTranslation();
  const title = translatePerfumeCategoryTitle(t, category, { quantity });

  return (
    <>
      {!noIcon && <PerfumeCategoryIcon category={category} />}
      {title}
    </>
  );
}

export function PerfumeCategoryIcon({ category }: { category: ComponentCategory }) {
  const { t } = useTranslation();
  return (
    <Box
      component="img"
      sx={{
        height: "1.4em",
        position: "relative",
        top: "0.15em",
        mr: 1,
      }}
      alt={translatePerfumeCategoryTitle(t, category)}
      src={perfumeCategoryIcon(category)}
    />
  );
}
