import {
  ComponentCategory,
  type Perfume,
  type PerfumeComponent,
  perfumeVolume,
  perfumeCountComponents,
} from "@/models";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { PerfumeCategoryTitle } from "./PerfumeCategoryTitle";
import { useMemo } from "react";
import { FormulaNumber } from "./formulaNumber";
import { makeDiplome } from "./makeDiplome";
import { capitalizeIngredientName } from "@/services";
import { useTranslation } from "react-i18next";

const perfumeDetailDateFormat = new Intl.DateTimeFormat("fr-FR", {
  dateStyle: "full",
});

export function PerfumeDetails({ perfume }: { perfume: Perfume }) {
  const volume = useMemo(() => perfumeVolume(perfume), [perfume]);
  const numberOfComponents = perfumeCountComponents(perfume);

  // const componentsByCategory = useMemo(() => {
  //   const filterComponents = (category: ComponentCategory) =>
  //     removeEmptyComponents(perfume.components).filter(
  //       (c) => c.category === category
  //     );

  //   return {
  //     notesDeTete: filterComponents(ComponentCategory.NOTE_DE_TETE),
  //     notesDeCoeur: filterComponents(ComponentCategory.NOTE_DE_COEUR),
  //     notesDeFond: filterComponents(ComponentCategory.NOTE_DE_FOND),
  //   };
  // }, [perfume.components]);

  const handleDiploma = async ({ noBackground }: { noBackground: boolean }) => {
    const diplomeBlob = await makeDiplome({
      perfume,
      noBackground,
    });
    const url = URL.createObjectURL(diplomeBlob);
    window.open(url);
    URL.revokeObjectURL(url);
  };

  const { t } = useTranslation();
  return (
    <Stack alignItems="center" gap={1}>
      <Typography variant="h3" gutterBottom>
        {perfume.name}
      </Typography>
      <Typography variant="body1">
        {t("summaryCreated", { date: perfumeDetailDateFormat.format(perfume.created) })}
      </Typography>
      <Typography variant="body1">
        {perfume.workshopLocation &&
          t("summaryLocation", { location: perfume.workshopLocation.address })}
        <> </>
        {perfume.perfumeOrgan && t("summaryOrgan", { organ: perfume.perfumeOrgan.name })}
      </Typography>
      <Typography variant="body1">
        {t("summaryFormulaNumber")} <FormulaNumber formulaNumber={perfume.formulaNumber} />
      </Typography>
      <Typography variant="body1" gutterBottom>
        {t("summaryComponentsVolume", { numberOfComponents, volume })}
      </Typography>
      {/* 
      <PerfumeCategory
        category={ComponentCategory.NOTE_DE_TETE}
        components={componentsByCategory.notesDeTete}
      />
      <PerfumeCategory
        category={ComponentCategory.NOTE_DE_COEUR}
        components={componentsByCategory.notesDeCoeur}
      />
      <PerfumeCategory
        category={ComponentCategory.NOTE_DE_FOND}
        components={componentsByCategory.notesDeFond}
      /> */}
      {/* 
      {perfume.username && (
        <Typography variant="subtitle1" gutterBottom>
          Author: {perfume.username} / {perfume.email}
        </Typography>
      )} */}
      <Button onClick={() => handleDiploma({ noBackground: false })}>{t("showDiploma")}</Button>
      <Button onClick={() => handleDiploma({ noBackground: true })}>
        {t("showDiploma")} (sans fond)
      </Button>
    </Stack>
  );
}

export function PerfumeCategory({
  category,
  components,
}: {
  category: ComponentCategory;
  components: PerfumeComponent[];
}) {
  if (components.length === 0) {
    return <></>;
  }

  return (
    <>
      <Stack direction="row" gap={2}>
        ___________________
        <Typography color="text.secondary" variant="subtitle1">
          <PerfumeCategoryTitle category={category} quantity={components.length} />
        </Typography>
        ___________________
      </Stack>
      <Stack
        direction="row"
        sx={{
          width: 1,
          px: {
            xs: 1,
            sm: 4,
          },
        }}
        gap={4}
        flexWrap="wrap"
      >
        {components.map((component, i) => (
          <Box
            key={i}
            sx={{
              alignItems: "start",
              flexGrow: 1,
              //   backgroundColor: "rgba(0,0,0,0.1)",
            }}
          >
            <Typography variant="h5">{capitalizeIngredientName(component.name)}</Typography>
            <Typography variant="body1">{component.volume} ml</Typography>
            <Typography variant="body2"></Typography>
          </Box>
        ))}
      </Stack>
    </>
  );
}
