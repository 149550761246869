import { useEffect, useMemo } from "react";
import Container from "@mui/material/Container";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import CardActions from "@mui/material/CardActions";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import DelayedLinearProgress from "@/components/DelayedLinearProgress";
import {
  PageLink,
  allAvailableIngredients,
  makeIngredient,
  perfumeUserFullname,
  perfumeVolume,
  userIsAdmin,
} from "@/models";
import { services } from "@/injectServices";
import { renderLocalDatetime, useRequiredParam } from "@/components";
import { useLoading } from "@/hooks/Loading";
import { useNavigate } from "react-router-dom";
import {
  deletePerfume,
  useAuthentication,
  useIngredientDiplayName,
  useTrackPerfume,
} from "@/services";
import { FormulaNumber } from "@/components/formulaNumber";
import TableContainer from "@mui/material/TableContainer";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableFooter from "@mui/material/TableFooter";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";

export function PerfumeDetailsPage() {
  useEffect(() => {
    document.title = `Galimard Workshop: Perfume details`;
  });
  const perfumeId = useRequiredParam("perfumeId");

  const [pageLoading, pageLoadingDispatcher] = useLoading();

  const navigate = useNavigate();

  const { perfume } = useTrackPerfume({
    service: services().perfumeService,
    loadingDispatcher: pageLoadingDispatcher,
    perfumeId,
  });

  const handleEditPerfume = () => {
    if (perfume !== null) {
      navigate(
        PageLink.perfumeWorkshop({
          perfumeId: perfume.recipeId,
        }),
      );
    }
  };

  const handleDeletePerfume = async () => {
    const name = perfume?.name ? `'${perfume.name}'` : "";
    const author =
      perfume?.username || perfume?.email
        ? `made by ${perfumeUserFullname(perfume)} ${perfume.email}`
        : "";
    const confirmationMessage = `Are you sure to delete the perfume? ${name} ${author}`;
    if (window.confirm(confirmationMessage)) {
      await deletePerfume(services().perfumeService, pageLoadingDispatcher, perfumeId);
      navigate(PageLink.Perfumes);
    }
  };

  const composition = useMemo(
    () =>
      (perfume?.components || [])
        .map((component) => {
          const [ingredient] = makeIngredient(component, allAvailableIngredients, {
            createMissingIngredient: true,
          });
          return {
            component,
            ingredient,
          };
        })
        .filter((c) => c.ingredient !== null),
    [perfume?.components],
  );

  const { user } = useAuthentication(services().authenticationService);
  const isAdmin = userIsAdmin(user);

  const ingredientDisplayName = useIngredientDiplayName();

  return (
    <Container>
      <DelayedLinearProgress loading={pageLoading} />
      {perfume && (
        <Card variant="outlined">
          <CardContent>
            <Typography variant="h5" color="text.secondary" gutterBottom>
              Perfume <FormulaNumber formulaNumber={perfume.formulaNumber} />
            </Typography>

            <Typography variant="h5" component="div">
              {perfume.name}
            </Typography>

            {perfume.username && (
              <Typography variant="h6" component="div">
                créé par {perfumeUserFullname(perfume)}
              </Typography>
            )}

            <Typography sx={{ mb: 1.5 }} color="text.secondary">
              {perfume.email}
            </Typography>

            <Typography variant="body1" color="text.secondary" gutterBottom>
              Créé le {renderLocalDatetime(perfume.created)}
              {perfume.workshopLocation && <> au {perfume.workshopLocation.address}</>}
            </Typography>
            {perfume.perfumeOrgan && (
              <Typography variant="body1" color="text.secondary" gutterBottom>
                sur l&apos;orge à parfum {perfume.perfumeOrgan.name}
              </Typography>
            )}

            <TableContainer component={Paper}>
              <Table sx={{ maxWidth: 400 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>Name</TableCell>
                    <TableCell align="right">Reference Galimard</TableCell>
                    <TableCell align="right">Volume</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {composition.map(({ component, ingredient }, i) => (
                    <TableRow key={i} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                      <TableCell component="th" scope="row">
                        {ingredientDisplayName(ingredient)}
                      </TableCell>
                      <TableCell align="right">#{ingredient?.galimardReference}</TableCell>
                      <TableCell align="right">{component.volume}&nbsp;ml</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
                <TableFooter>
                  <TableRow>
                    <TableCell />
                    <TableCell />
                    <TableCell align="right">Total: {perfumeVolume(perfume)}&nbsp;ml</TableCell>
                  </TableRow>
                </TableFooter>
              </Table>
            </TableContainer>

            {isAdmin && (
              <CardActions>
                <Button size="small" onClick={handleEditPerfume}>
                  Edit Perfume
                </Button>
                <Button color="danger" size="small" onClick={handleDeletePerfume}>
                  Delete
                </Button>
              </CardActions>
            )}
          </CardContent>
        </Card>
      )}
    </Container>
  );
}
