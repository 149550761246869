import Typography from "@mui/material/Typography";
import ButtonBase from "@mui/material/ButtonBase";
import DialogContent from "@mui/material/DialogContent";
import SearchIcon from "@mui/icons-material/Search";
import IconButton from "@mui/material/IconButton";
import InputBase from "@mui/material/InputBase";
import InputAdornment from "@mui/material/InputAdornment";
import ImageList from "@mui/material/ImageList";
import { styled, alpha } from "@mui/material/styles";
import ImageListItem from "@mui/material/ImageListItem";
import ImageListItemBar from "@mui/material/ImageListItemBar";

import { type ComponentCategory, type PerfumeIngredient } from "@/models";
import { PerfumeCategoryIcon, PerfumeCategoryTitle } from "./PerfumeCategoryTitle";
import { LargeDialog, useFullScreenDialog } from "./LargeDialog";
import { IngredientPicture, PictureSize } from "./IngredientPicture";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { sanitizeSearchString, useIngredientDiplayName } from "@/services";

export function IngredientDialog({
  category,
  availableIngredients,
  selectedIngredient,
  onIngredientChanged,
  open,
  onClose,
}: {
  category: ComponentCategory;
  availableIngredients: readonly PerfumeIngredient[];
  selectedIngredient: PerfumeIngredient | null;
  onIngredientChanged: (ingredient: PerfumeIngredient) => void;
  open: boolean;
  onClose: () => void;
}) {
  const fullScreen = useFullScreenDialog();

  const { visibleIngredients, search, setSearch } = useIngredientSearch({ availableIngredients });

  // on mobile, the search button activate search mode.
  // on desktop, searchMode is ignored, the search input is allways visible
  const searchInputAlwaysVisible = !fullScreen;
  const [searchMode, setSearchMode] = useState<boolean>(false);

  const searchInputVisible = searchInputAlwaysVisible || searchMode;
  const searchButtonVisible = fullScreen;

  const imageList: {
    column: number;
    size: PictureSize;
    gap: number;
    smallTitle: boolean;
  } = {
    column: fullScreen ? 2 : 3,
    size: fullScreen ? "thumbnail" : "large",
    gap: fullScreen ? 8 : 32,
    smallTitle: fullScreen,
  };

  const searchInputRef = useRef<HTMLInputElement>();

  const showSearchInput = useCallback(() => {
    setSearchMode((m) => !m);
    searchInputRef.current?.focus();
  }, []);

  // cleanup on close
  useEffect(() => {
    if (!open) {
      setSearchMode(false);
      setSearch("");
    }
  }, [open, setSearch]);

  const title =
    !fullScreen || !searchInputVisible ? (
      <PerfumeCategoryTitle category={category} quantity={visibleIngredients.length} />
    ) : (
      <PerfumeCategoryIcon category={category} />
    );

  const handleSelectIngredient = (ingredient: PerfumeIngredient) => {
    // setSelectedIngredient(ingredient)
    onIngredientChanged(ingredient);
    onClose();
  };

  const ingredientDisplayName = useIngredientDiplayName();
  return (
    <LargeDialog
      open={open}
      onClose={onClose}
      title={title}
      toolbarAction={
        <>
          <Search
            sx={{
              display: searchInputVisible ? "block" : "none",
              width: "20ch",
            }}
          >
            <StyledInputBase
              inputRef={(element: HTMLInputElement) => {
                searchInputRef.current = element;
                searchInputRef.current?.focus();
              }}
              placeholder="Search…"
              inputProps={{ "aria-label": "search" }}
              startAdornment={
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              }
              sx={{
                width: "100%",
              }}
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
          </Search>
          {searchButtonVisible && (
            <IconButton onClick={showSearchInput} size="large" aria-label="search" color="inherit">
              <SearchIcon />
            </IconButton>
          )}
        </>
      }
    >
      <DialogContent>
        <ImageList cols={imageList.column} gap={imageList.gap}>
          {visibleIngredients.map((ingredient) => (
            <ButtonBase
              key={ingredient.name}
              component="div"
              onClick={() => handleSelectIngredient(ingredient)}
              sx={{
                backgroundColor: ingredient === selectedIngredient ? "#f2f2f2" : undefined,
              }}
            >
              <ImageListItem>
                <IngredientPicture
                  ingredient={ingredient}
                  size={imageList.size}
                  sx={{
                    width: {
                      xs: "128px",
                      md: "256px",
                    },
                    height: {
                      xs: "128px",
                      md: "256px",
                    },
                  }}
                />

                <ImageListItemBar
                  title={
                    <Typography
                      variant={imageList.smallTitle ? "body1" : "h6"}
                      align="center"
                      sx={{
                        maxWidth: {
                          xs: "128px",
                          md: "256px",
                        },
                        whiteSpace: "normal",
                      }}
                    >
                      {ingredientDisplayName(ingredient)}
                    </Typography>
                  }
                  // subtitle={ingredient.description}
                  position="below"
                />
              </ImageListItem>
            </ButtonBase>
          ))}
        </ImageList>
      </DialogContent>
    </LargeDialog>
  );
}

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginRight: theme.spacing(2),
  marginLeft: theme.spacing(2),
  paddingLeft: theme.spacing(1),
  // width: "100%",
  // [theme.breakpoints.up("sm")]: {
  //   marginLeft: theme.spacing(3),
  //   width: "100%",
  // },
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  // width: "100%",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    // paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    // transition: theme.transitions.create("width"),
    width: "100%",
    // [theme.breakpoints.up("md")]: {
    //   width: "20ch",
    // },
  },
}));

function useIngredientSearch({
  availableIngredients,
}: {
  availableIngredients: readonly PerfumeIngredient[];
}) {
  const [search, setSearch] = useState("");

  const ingredientDisplayName = useIngredientDiplayName("");

  const visibleIngredients = useMemo(() => {
    const sanitizedSearch = sanitizeSearchString(search);
    if (!sanitizedSearch) {
      return [...availableIngredients];
    }
    return availableIngredients.filter((i) =>
      sanitizeSearchString(ingredientDisplayName(i)).includes(sanitizedSearch),
    );
  }, [availableIngredients, search, ingredientDisplayName]);

  return {
    visibleIngredients,
    search,
    setSearch,
  };
}
