import { perfumeUserFullname, type Perfume } from "@/models";

type DrawText = {
  text: string;
  x: number;
  y: number;
  size?: number;
};

export async function makeDiplome({
  perfume,
  noBackground,
}: {
  perfume: Perfume;
  noBackground: boolean;
}): Promise<Blob> {
  const { degrees, PDFDocument, StandardFonts, rgb } = await import("pdf-lib");

  let pdfDoc;
  if (noBackground) {
    pdfDoc = await PDFDocument.create();
    pdfDoc.addPage([476.22, 651.968]); // size of diplome-galimard.pdf
  } else {
    const diplomeResponse = await fetch("/diplome-galimard.pdf");
    const diplomeByes = await diplomeResponse.arrayBuffer();
    pdfDoc = await PDFDocument.load(diplomeByes);
  }

  const helveticaFont = await pdfDoc.embedFont(StandardFonts.Helvetica);

  const page = pdfDoc.getPage(0);
  const { width, height } = page.getSize();

  // console.log("Document size:", width, height);
  const texts: DrawText[] = [
    {
      text: perfumeUserFullname(perfume),
      x: 215,
      y: 165,
    },
    {
      text: `${perfume.formulaNumber}`,
      x: 455,
      y: 261,
    },
    {
      text: perfume.created.toLocaleDateString("fr-FR", {
        weekday: "long",
        year: "numeric",
        month: "long",
        day: "numeric",
      }),
      size: 10,
      x: 280,
      y: 280,
    },
  ];

  for (const text of texts) {
    page.drawText(text.text, {
      x: width - text.y,
      y: height - text.x,
      size: text.size ?? 14,
      font: helveticaFont,
      color: rgb(0, 0, 0),
      rotate: degrees(-90),
    });
  }

  const pdfBytes = await pdfDoc.save();

  return new Blob([pdfBytes], { type: "application/pdf" });
}
