import { useEffect } from "react";
import { useLoading } from "@/hooks/Loading";
import { services } from "@/injectServices";
import { useIngredientsInventory } from "@/services";
import DelayedLinearProgress from "@/components/DelayedLinearProgress";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import Paper from "@mui/material/Paper";
import TableBody from "@mui/material/TableBody";

export function IngredientInventoryPage() {
  useEffect(() => {
    document.title = `Galimard Workshop: Ingredient Inventory`;
  });

  const [pageLoading, pageLoadingDispatcher] = useLoading();

  const { inventory } = useIngredientsInventory(services().perfumeService, pageLoadingDispatcher);

  return (
    <>
      <Container>
        <Typography variant="h5">Ingredient Inventory</Typography>
        <DelayedLinearProgress loading={pageLoading} />
        {inventory !== null && (
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Ingrédient</TableCell>
                  <TableCell>Référence</TableCell>
                  <TableCell align="right">Volume</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {inventory.map((ingredientInventory) => (
                  <TableRow
                    key={ingredientInventory.ingredient.name}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      {ingredientInventory.ingredient.name}
                    </TableCell>
                    <TableCell align="right">
                      {ingredientInventory.ingredient.galimardReference}
                    </TableCell>
                    <TableCell align="right">{ingredientInventory.volumeUsed}&nbsp;ml</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </Container>
    </>
  );
}
