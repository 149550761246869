import {
  type PerfumeIngredient,
  ComponentCategory,
  defaultPerfumeIngredientIcon,
  type CategoriesOfIngredients,
  sortIngredients,
} from "./Perfume";
import { GalimardNoteDeTete } from "./GalimardNoteDeTete";
import { GalimardNoteDeCoeur } from "./GalimardNoteDeCoeur";
import { GalimardNoteDeFond } from "./GalimardNoteDeFond";
import { type GalimardIngredient } from "./GalimardIngredient";

function adaptPerfumeIngredient(
  galimard: GalimardIngredient,
  category: ComponentCategory,
): PerfumeIngredient {
  if (galimard.displayName !== undefined) {
    galimard.displayName.fr = galimard.displayName.fr.replace("'", "’");
  }
  return {
    name: galimard.name,
    displayName: galimard.displayName ?? null,
    description: galimard.description ?? null,
    advice: galimard.advice ?? "",
    galimardReference: galimard.galimardReference,
    category: category,
    icon: galimard.icon ? `/ingredients/${galimard.icon}` : defaultPerfumeIngredientIcon,
  };
}

function adaptCategoryOfIngredients(
  category: ComponentCategory,
  galimardIngredients: GalimardIngredient[],
) {
  return sortIngredients(
    galimardIngredients.map((ingredient) => adaptPerfumeIngredient(ingredient, category)),
  );
}

export const AvailablePerfumeIngredients: CategoriesOfIngredients = {
  [ComponentCategory.NOTE_DE_TETE]: adaptCategoryOfIngredients(
    ComponentCategory.NOTE_DE_TETE,
    GalimardNoteDeTete,
  ),
  [ComponentCategory.NOTE_DE_COEUR]: adaptCategoryOfIngredients(
    ComponentCategory.NOTE_DE_COEUR,
    GalimardNoteDeCoeur,
  ),
  [ComponentCategory.NOTE_DE_FOND]: adaptCategoryOfIngredients(
    ComponentCategory.NOTE_DE_FOND,
    GalimardNoteDeFond,
  ),
} as const;

export const allAvailableIngredients: PerfumeIngredient[] = Object.values(
  AvailablePerfumeIngredients,
).flat();
