import { PerfumeRepositoryFirebase } from "./PerfumeRepositoryFirebase";
import { AuthenticationRepository } from "./AuthenticationRepository";
import type { FirebaseWrapper } from "@/infra/FirebaseWrapper";

export class Repositories {
  perfumeRepository: PerfumeRepositoryFirebase;
  authenticationRepository: AuthenticationRepository;

  constructor(firebaseWrapper: FirebaseWrapper) {
    this.perfumeRepository = new PerfumeRepositoryFirebase(firebaseWrapper.firestore);
    this.authenticationRepository = new AuthenticationRepository(firebaseWrapper);
  }
}
