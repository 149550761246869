import { isLanguage, Language, PerfumeIngredient } from "@/models";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";

export const defaultLanguage: Language = "fr";

export function capitalizeIngredientName(name: string) {
  return name
    .split(" ")
    .filter((w) => !!w)
    .map((w) => {
      const firstLetter = w[0].toLocaleUpperCase();
      const rest = w.slice(1).toLocaleLowerCase();
      return firstLetter + rest;
    })
    .join(" ");
}

export function useIngredientDiplayName(unknown?: string) {
  const { i18n } = useTranslation();
  const activeLocale = i18n.resolvedLanguage;

  if (!isLanguage(activeLocale)) {
    throw Error(`Unsupported language for ingredient l18n: ${activeLocale}`);
  }

  return useCallback(
    (ingredient: PerfumeIngredient | null) =>
      ingredient === null
        ? (unknown ?? "(Unknown ingredient)")
        : ingredient.displayName === null
          ? capitalizeIngredientName(ingredient.name)
          : ingredient.displayName[activeLocale] || ingredient.displayName[defaultLanguage],

    [activeLocale, unknown],
  );
}
