import { v4 as uuidv4 } from "uuid";
import { makeIngredient, allAvailableIngredients } from "@/models";
import type { ComponentCategory, Perfume, PerfumeIngredient } from "@/models";

export interface EditComponentViewModel {
  componentId: string;
  category: ComponentCategory;
  ingredient: PerfumeIngredient | null;
  displayName: string;
  volume: string;
}

export interface EditPerfumeViewModel {
  name: string;
  email: string;
  title: string;
  username: string;
  userLastName: string;
  components: EditComponentViewModel[];
  customIngredients: PerfumeIngredient[];
}

export function adaptEditPerfumeViewModel(perfume: Perfume): EditPerfumeViewModel {
  const customIngredients: PerfumeIngredient[] = [];

  return {
    name: perfume.name,
    email: perfume.email,
    title: perfume.title,
    username: perfume.username,
    userLastName: perfume.userLastName,
    components: perfume.components.map((component) => {
      const [ingredient, created] = makeIngredient(component, allAvailableIngredients, {
        createMissingIngredient: true,
      });
      if (ingredient !== null && created) {
        customIngredients.push(ingredient);
      }

      return {
        componentId: uuidv4(),
        category: component.category,
        ingredient: ingredient,
        volume: component.volume.toString(),
        displayName: "",
      };
    }),
    customIngredients,
  };
}

export function adaptPerfume(
  backendPerfume: Perfume,
  perfumeViewModel: EditPerfumeViewModel,
): Perfume {
  return {
    recipeId: backendPerfume.recipeId,
    formulaNumber: backendPerfume.formulaNumber,
    created: backendPerfume.created,
    name: perfumeViewModel.name,
    email: perfumeViewModel.email,
    title: perfumeViewModel.title,
    username: perfumeViewModel.username,
    userLastName: perfumeViewModel.userLastName,
    components: perfumeViewModel.components.map((component) => ({
      name: component.ingredient?.name ?? "",
      volume: parseInt(component.volume) || 0,
      category: component.category,
    })),
    workshopLocation: backendPerfume.workshopLocation,
    perfumeOrgan: backendPerfume.perfumeOrgan,
  };
}
