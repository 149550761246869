import { useCallback, useState, type MouseEvent } from "react";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Button from "@mui/material/Button";
import { PageLink, userIsAdmin } from "@/models";
import { services } from "@/injectServices";
import { useAuthentication } from "@/services";
import Link from "@mui/material/Link";
import LanguageMenu from "@/components/LanguageMenu";

interface Page {
  title: string;
  link: string;
}

export function MainAppBar() {
  const pages: Array<Page> = [
    {
      title: "Atelier de création de parfum",
      link: PageLink.PerfumeWorkshop,
    },
    {
      title: "Compte",
      link: PageLink.Account,
    },
  ];

  const { user } = useAuthentication(services().authenticationService);

  if (userIsAdmin(user)) {
    pages.push({
      title: "Admin: Liste des parfums",
      link: PageLink.Perfumes,
    });
  }

  const [anchorElNav, setAnchorElNav] = useState<null | HTMLElement>(null);
  const handleOpenNavMenu = (event: MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = useCallback(() => {
    setAnchorElNav(null);
  }, []);

  const navigate = useNavigate();

  const onNavigate = useCallback(
    (link: string) => {
      handleCloseNavMenu();
      navigate(link);
    },
    [handleCloseNavMenu, navigate],
  );

  const DeskTopBar = () => (
    <>
      <Typography
        variant="h6"
        noWrap
        component={RouterLink}
        to="/"
        sx={{
          mr: 2,
          ml: 1,
          display: { xs: "none", md: "flex" },
          fontWeight: 100,
          letterSpacing: ".3rem",
          color: "inherit",
          textDecoration: "none",
        }}
      >
        Galimard
      </Typography>

      <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
        {pages.map((page: Page, index) => (
          <Button
            variant="text"
            color="menu"
            key={index}
            onClick={() => onNavigate(page.link)}
            sx={{ my: 2, display: "block" }}
          >
            {page.title}
          </Button>
        ))}
      </Box>
    </>
  );

  const MobileBar = () => (
    <>
      <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
        <IconButton
          size="large"
          aria-label="account of current user"
          aria-controls="menu-appbar"
          aria-haspopup="true"
          onClick={handleOpenNavMenu}
          color="inherit"
        >
          <MenuIcon />
        </IconButton>
        <Menu
          disableScrollLock
          id="menu-appbar"
          anchorEl={anchorElNav}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          keepMounted
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          open={Boolean(anchorElNav)}
          onClose={handleCloseNavMenu}
          sx={{
            display: { xs: "block", md: "none" },
          }}
        >
          {pages.map((page, index) => (
            <MenuItem key={index} onClick={() => onNavigate(page.link)}>
              {page.title}
            </MenuItem>
          ))}
        </Menu>
      </Box>
      <Typography
        variant="h6"
        noWrap
        component={RouterLink}
        to="/"
        sx={{
          mr: 2,
          display: { xs: "box", md: "none" },
          fontWeight: 100,
          letterSpacing: ".3rem",
          color: "inherit",
          textDecoration: "none",
        }}
      >
        Galimard
      </Typography>
    </>
  );

  return (
    <AppBar position="static">
      <Toolbar disableGutters>
        <Box sx={{ ml: 2 }}>
          <Link component={RouterLink} to="/">
            <img
              src="/galimard-logo-small-black.png"
              style={{ width: "44px" }}
              alt="logo"
              loading="lazy"
            />
          </Link>
        </Box>

        <DeskTopBar />
        <MobileBar />
        <LanguageMenu />
      </Toolbar>
    </AppBar>
  );
}
