import { GalimardIngredient } from "./GalimardIngredient";

export const GalimardNoteDeCoeur: GalimardIngredient[] = [
  {
    name: "BOIS DOUX",
    galimardReference: "692",
    icon: "bois-doux",
  },
  {
    name: "AIR DE PROVENCE",
    displayName: {
      fr: "Air de Provence",
      en: "",
    },
    galimardReference: "448",
    icon: "air-de-provence",
  },
  {
    name: "ALDEHYDAL",
    galimardReference: "295",
    icon: "aldehydal",
  },
  {
    name: "CHEVREFEUILLE",
    displayName: {
      fr: "Chèvrefeuille",
      en: "",
    },
    galimardReference: "445",
    icon: "chevrefeuille",
  },
  {
    name: "COCKTAIL",
    galimardReference: "591",
    icon: "cocktail",
  },
  {
    name: "ACCORD JASMIN",
    displayName: {
      fr: "Accord Jasmin",
      en: "",
    },
    galimardReference: "690",
    icon: "accord-jasmin",
  },
  {
    name: "BAMBOU",
    galimardReference: "922",
    icon: "bambou",
  },
  {
    name: "COMPLEXE FRUITE",
    displayName: {
      fr: "Complexe Fruité",
      en: "",
    },
    galimardReference: "627",
    icon: "complexe-fruite",
  },
  {
    name: "FIGUE",
    galimardReference: "903",
    icon: "figue",
  },
  {
    name: "FLEUR DE GRENADIER",
    displayName: {
      fr: "Fleur de Grenadier",
      en: "",
    },
    galimardReference: "905",
    icon: "fleur-de-grenadier",
  },
  {
    name: "FLEUR DE JACINTHE",
    galimardReference: "630",
    icon: "fleur-de-jacinthe",
  },
  {
    name: "FLEUR DE LILAS",
    galimardReference: "629",
    icon: "fleur-de-lilas",
  },
  {
    name: "FLEURI YLANG",
    galimardReference: "628",
    icon: "fleuri-ylang",
  },
  {
    name: "FREESIA",
    galimardReference: "463",
    description: { fr: ``, en: "" },
    advice: ``,
    icon: "freesia",
  },
  {
    name: "GARDENIA",
    galimardReference: "904",
    icon: "gardenia",
  },
  {
    name: "GERANIUM",
    displayName: {
      fr: "Géranium",
      en: "",
    },
    galimardReference: "694",
    icon: "geranium",
  },
  {
    name: "GINGEMBRE",
    galimardReference: "955",
    icon: "gingembre",
  },
  {
    name: "JASMIN MUSQUE",
    displayName: {
      fr: "Jasmin Musqué",
      en: "",
    },
    galimardReference: "632",
    icon: "jasmin-musque",
  },
  {
    name: "JASMIN ORANGE",
    galimardReference: "633",
    icon: "jasmin-orange",
  },
  {
    name: "JASMIN SAUVAGE",
    galimardReference: "696",
    icon: "jasmin-sauvage",
  },
  {
    name: "MAGNOLIA",
    galimardReference: "907",
    icon: "magnolia",
  },
  {
    name: "MIMOSA",
    galimardReference: "???",
    icon: "mimosa",
  },
  {
    name: "MUGUET",
    galimardReference: "638",
    icon: "muguet",
  },
  {
    name: "MUGUET DE MAI",
    displayName: {
      fr: "Muguet de Mai",
      en: "",
    },
    galimardReference: "639",
    icon: "muguet-de-mai",
  },
  {
    name: "MUGUET DES BOIS",
    displayName: {
      fr: "Muguet des bois",
      en: "",
    },
    galimardReference: "640",
    icon: "muguet-des-bois",
  },
  {
    name: "MUGUET MUSQUE",
    displayName: {
      fr: "Muguet Musqué",
      en: "",
    },
    galimardReference: "698",
    icon: "muguet-musque",
  },
  {
    name: "NEROLI",
    galimardReference: "699",
    icon: "neroli",
  },
  {
    name: "NOTE CANNELLE",
    galimardReference: "444",
    // prompt: "cinnamon stick and cinnamon powder on a small wooden plank, detailed, white background"
    icon: "note-cannelle",
  },
  {
    name: "NOTE MARINE",
    galimardReference: "488",
    // prompt: "calm sea in a scent bottle, detailed, watercolor, white background"
    icon: "note-marine",
  },
  {
    name: "NOTE OCEANE",
    displayName: {
      fr: "Note Océane",
      en: "",
    },
    galimardReference: "927",
    // prompt: "scent of note marine, ocean wave in a bottle, detailed, watercolor, white background"
    icon: "note-oceane",
  },
  {
    name: "NOTE TABAC",
    galimardReference: "643",
    // prompt: "small bag of tobacco on dried tobacco leave, detailed, white background"
    icon: "note-tabac",
  },
  {
    name: "NOTE CIVETTE",
    galimardReference: "642",
    // prompt: "painting of the face of a civet, stylished, watercolor, white background"
    icon: "note-civette",
  },
  {
    name: "PIN BOISE",
    displayName: {
      fr: "Pin Boisé",
      en: "",
    },
    galimardReference: "703",
    // prompt: "painting of a Pinus pinaster, stylished, watercolor, white background"
    icon: "pin-boise",
  },
  {
    name: "PIVOINE",
    galimardReference: "923",
    // prompt: "single red Peony with a few leaves, stylished, watercolor, white background"
    icon: "pivoine",
  },
  {
    name: "PRUNE",
    galimardReference: "914",
    description: { fr: ``, en: "" },
    advice: ``,
    // prompt: "sliced Plum on a small wooden plank, detailed, white background"
    icon: "prune",
  },
  {
    name: "REGLISSE",
    displayName: {
      fr: "Réglisse",
      en: "",
    },
    galimardReference: "565",
    // prompt: "liquorice on a small wooden plank, detailed, white background"
    icon: "reglisse",
  },
  {
    name: "ROSE D'ORIENT",
    displayName: {
      fr: "Rose d'Orient",
      en: "",
    },
    galimardReference: "646",
    // prompt: "Rose bush, watercolor detailed, white background"
    icon: "rose-orient",
  },
  {
    name: "ROSE PETALES",
    displayName: {
      fr: "Rose pétale",
      en: "",
    },
    galimardReference: "647",
    // prompt: "bowl of rose petals, watercolor detailed, white background"
    icon: "rose-petales",
  },
  {
    name: "ROSE SAUVAGE",
    galimardReference: "648",
    // prompt: "closeup on wild rose in a forest, watercolor detailed, white background"
    icon: "rose-sauvage",
  },
  {
    name: "TIARE",
    displayName: {
      fr: "Tiaré",
      en: "",
    },
    galimardReference: "919",
    // prompt: photoshop generative fill with seeds from google image
    icon: "tiare",
  },
  {
    name: "TILLEUL",
    galimardReference: "915",
    // prompt: "painting of a linden branch with leaves, watercolor, white background"
    icon: "tilleul",
  },
  {
    name: "VIOLETTE DE PARME",
    displayName: {
      fr: "Violette de Parme",
      en: "",
    },
    galimardReference: "935",
    icon: "violette-de-parme",
  },
  {
    name: "SAFRAN",
    displayName: {
      fr: "Safran",
      en: "Saffron",
    },
    galimardReference: "???",
    icon: "safran",
  },
];
