import { Dispatch, useState, SetStateAction, useEffect } from "react";

export type LoadingDispatcher = Dispatch<SetStateAction<number>>;

export function useLoading(): [boolean, LoadingDispatcher] {
  const [loadingCounter, dispatch] = useState(0);

  const loading = loadingCounter != 0;

  return [loading, dispatch];
}

export async function showLoader<T>(dispatch: LoadingDispatcher, promise: Promise<T>): Promise<T> {
  dispatch((counter) => counter + 1);
  try {
    // await new Promise((resolve) => setTimeout(resolve, 3000));
    return await promise;
  } finally {
    dispatch((counter) => counter - 1);
  }
}

export function useShowLoaderIf(dispatch: LoadingDispatcher, condition: boolean): void {
  useEffect(() => {
    if (condition === false) {
      return;
    }

    dispatch((counter) => counter + 1);
    return () => {
      dispatch((counter) => counter - 1);
    };
  }, [dispatch, condition]);
}
