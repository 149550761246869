import { useEffect } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import Container from "@mui/material/Container";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Alert from "@mui/material/Alert";
import Button from "@mui/material/Button";
import DelayedLinearProgress from "@/components/DelayedLinearProgress";
import { services } from "@/injectServices";
import { useLoading } from "@/hooks/Loading";
import { useTrackPerfume, useCreateNewPerfume, useCreatePerfume } from "@/services";
import { PerfumeTunnel } from "@/components/PerfumeTunnel";
import Stack from "@mui/material/Stack";
import { PerfumeTunnelPageStepId, adaptPerfumeTunnelPageStepId } from "@/models";
import { useTranslation } from "react-i18next";

export function PerfumeTunnelPage() {
  const [pageLoading, pageLoadingDispatcher] = useLoading();

  const { workshopLocationId, organId, perfumeId, step } = useParams();
  const [searchParams] = useSearchParams();

  const service = services().perfumeService;

  const { cantCreatePerfumeError } = useCreatePerfume({
    service,
    loadingDispatcher: pageLoadingDispatcher,
    workshopLocationId: workshopLocationId ?? null,
    organId: organId ?? null,
    perfumeId: perfumeId ?? null,
    create: !!searchParams.get("create"),
  });

  const { perfume } = useTrackPerfume({
    service,
    loadingDispatcher: pageLoadingDispatcher,
    perfumeId: perfumeId ?? null,
  });

  useEffect(() => {
    const suffix = perfume?.formulaNumber ? `: #${perfume.formulaNumber}` : "";
    document.title = `Galimard Workshop${suffix}`;
  }, [perfume?.formulaNumber]);

  const { createNewPerfume, canCreateNewPerfume } = useCreateNewPerfume({
    service,
    loadingDispatcher: pageLoadingDispatcher,
    workshopLocationId: perfume?.workshopLocation?.workshopLocationId,
    organId: perfume?.perfumeOrgan?.perfumeOrganId,
  });

  const activePageNumber =
    adaptPerfumeTunnelPageStepId(step) ?? PerfumeTunnelPageStepId.Identification;

  const { t } = useTranslation();
  return (
    <Container component="main" maxWidth="md" sx={{ mb: 4 }}>
      <DelayedLinearProgress loading={pageLoading} />
      <Paper variant="outlined" sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}>
        <Stack direction="row" justifyContent="end">
          <Button disabled={!canCreateNewPerfume} color="secondary" onClick={createNewPerfume}>
            {t("creerUnNouveauParfum")}
          </Button>
        </Stack>
        <Typography component="h1" variant="h4" align="center">
          {t("atelierDeCreation")}
        </Typography>
        {perfume && (
          <PerfumeTunnel
            key={perfume.recipeId}
            backendPerfume={perfume}
            activePageNumber={activePageNumber}
          />
        )}

        {cantCreatePerfumeError && <Alert severity="warning">{cantCreatePerfumeError}</Alert>}
      </Paper>
    </Container>
  );
}
