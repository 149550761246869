import { PermissionDeniedError } from "@/models";
import { onSnapshot } from "firebase/firestore";
import type {
  FirestoreError,
  Unsubscribe,
  DocumentReference,
  DocumentData,
} from "firebase/firestore";

export type TrackModelParams<Model, DBModel extends DocumentData> = {
  document: DocumentReference<Model, DBModel>;
  onChange: (movie: Model | null) => void;
  onError?: (error: PermissionDeniedError) => void;
};

export function trackModelById<Model, DBModel extends DocumentData>({
  document,
  onChange,
  onError,
}: TrackModelParams<Model, DBModel>): Unsubscribe {
  const unSubscribe = onSnapshot(document, {
    next: (dto) => {
      onChange(dto.exists() ? dto.data() : null);
    },
    error: (error: FirestoreError) => {
      if (error.code === "permission-denied") {
        const domainError = new PermissionDeniedError(
          `Permission error: can't track model ${document.path}/${document.id}`,
          error,
        );
        if (onError) {
          onError(domainError);
          return;
        } else {
          throw domainError;
        }
      }
      throw Error(
        `Firestore error while tracking movie $${document.path}/${document.id}: ${error.code} ${error.message}`,
      );
    },
  });
  return unSubscribe;
}
