import { v4 as uuidv4 } from "uuid";
import { Dispatch, SetStateAction } from "react";
import type { CategoriesOfIngredients, ComponentCategory, PerfumeIngredient } from "@/models";
import type { EditPerfumeViewModel, EditComponentViewModel } from "./EditPerfumeViewModel";

export interface PerfumeEditor {
  updatePerfumeViewModel: Dispatch<SetStateAction<EditPerfumeViewModel>>;
  ingredientDialogOpen: IngredientDialogOpen;
  setIngredientDialogOpen: Dispatch<SetStateAction<IngredientDialogOpen>>;
  availableIngredients: CategoriesOfIngredients;
  perfumeViewModel: EditPerfumeViewModel;
  totalVolume: number;
}

export type IngredientDialogOpen = {
  componentId: string;
} | null;

function updatePerfumeComponent(
  editor: PerfumeEditor,
  componentId: string,
  edit: (component: EditComponentViewModel) => EditComponentViewModel,
) {
  editor.updatePerfumeViewModel((vm): EditPerfumeViewModel => {
    const components = vm.components.map((component) =>
      component.componentId === componentId ? edit(component) : component,
    );

    return {
      ...vm,
      components,
    };
  });
}

export function addComponentWithDialog(editor: PerfumeEditor, category: ComponentCategory) {
  const newComponent: EditComponentViewModel = {
    componentId: uuidv4(),
    category: category,
    ingredient: null,
    displayName: "",
    volume: "",
  };
  editor.updatePerfumeViewModel(
    (vm): EditPerfumeViewModel => ({
      ...vm,
      components: [...vm.components, newComponent],
    }),
  );
  openComponentIngredientDialog(editor, newComponent.componentId);
}

export function openComponentIngredientDialog(editor: PerfumeEditor, componentId: string) {
  editor.setIngredientDialogOpen({
    componentId: componentId,
  });
}

export function closeComponentIngredientDialog(editor: PerfumeEditor) {
  editor.setIngredientDialogOpen(null);
}

export function deleteComponent(editor: PerfumeEditor, componentIdToRemove: string) {
  editor.updatePerfumeViewModel(
    (vm): EditPerfumeViewModel => ({
      ...vm,
      components: vm.components.filter((c) => c.componentId !== componentIdToRemove),
    }),
  );
}

export function editComponent(
  editor: PerfumeEditor,
  componentId: string,
  {
    displayName,
    ingredient,
    volume,
  }: {
    displayName?: string;
    ingredient?: PerfumeIngredient | null;
    volume?: string;
  },
) {
  updatePerfumeComponent(editor, componentId, (component) => {
    const newComponent = { ...component };

    if (displayName !== undefined) {
      newComponent.displayName = displayName;
    }

    if (ingredient !== undefined) {
      newComponent.ingredient = ingredient;
    }

    if (volume !== undefined) {
      newComponent.volume = adaptVolumeViewModel(volume);
    }

    return newComponent;
  });
}

function adaptVolumeViewModel(volume: string): string {
  const volumeInt = parseInt(volume) || null;
  if (volumeInt === null) {
    return volume;
  }

  const bounded = Math.min(100, Math.max(0, volumeInt));
  return bounded.toLocaleString();
}

export function setPerfumeProperty(
  editor: PerfumeEditor,
  changes: {
    newName?: string;
    newEmail?: string;
    newTitle?: string;
    newUsername?: string;
    newUserLastName?: string;
  },
) {
  editor.updatePerfumeViewModel(
    (vm): EditPerfumeViewModel => ({
      ...vm,
      name: changes.newName ?? vm.name,
      email: changes.newEmail ?? vm.email,
      title: changes.newTitle ?? vm.title,
      username: changes.newUsername ?? vm.username,
      userLastName: changes.newUserLastName ?? vm.userLastName,
    }),
  );
}
