import { Repositories } from "@/repositories";
import { PerfumeRecipiesService, AuthenticationService } from "@/services";

export class Services {
  repositories: Repositories;
  // First implementation was a getter that create a new perfumeService at each call.
  // Since PerfumeRecipiesService is stateless by design, there is no reason to not share an instance
  perfumeService: PerfumeRecipiesService;
  authenticationService: AuthenticationService;

  constructor(repositories: Repositories) {
    this.repositories = repositories;
    this.perfumeService = {
      perfumeRepository: this.repositories.perfumeRepository,
    };
    this.authenticationService = new AuthenticationService(repositories.authenticationRepository);
  }
}

let globalServices: Services | null = null;

export function initServices(services: Services) {
  if (globalServices !== null) {
    throw Error("global Services already initialised.");
  }
  globalServices = services;
}

export function services(): Services {
  if (globalServices === null) {
    throw Error("global Services not initialised.");
  }
  return globalServices;
}
