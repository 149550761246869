import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import Input from "@mui/material/Input";
import InputAdornment from "@mui/material/InputAdornment";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import AddIcon from "@mui/icons-material/AddBoxOutlined";
import DeleteIcon from "@mui/icons-material/DeleteOutline";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import { SxProps, Theme } from "@mui/material/styles";

import { ComponentCategory, type PerfumeIngredient } from "@/models";
import {
  type PerfumeEditor,
  addComponentWithDialog,
  deleteComponent,
  editComponent,
  setPerfumeProperty,
  openComponentIngredientDialog,
  closeComponentIngredientDialog,
} from "@/services/perfume-editor";
import type { EditComponentViewModel } from "@/services/perfume-editor/EditPerfumeViewModel";
import { PerfumeCategoryTitle } from "./PerfumeCategoryTitle";
import { IngredientDialog } from "./IngredientDialog";
import { IngredientPicture } from "./IngredientPicture";
import { useIngredientDiplayName } from "@/services";
import { useTranslation } from "react-i18next";
import { useCallback } from "react";

export function PerfumeComposer({ editor, hide }: { editor: PerfumeEditor; hide: boolean }) {
  const perfumeViewModel = editor.perfumeViewModel;

  const { t } = useTranslation();
  return (
    <Box
      sx={{
        display: hide ? "none" : undefined,
      }}
    >
      <FormControl>
        <TextField
          required
          label={t("perfumeName")}
          value={perfumeViewModel.name}
          variant="standard"
          onChange={(e) =>
            setPerfumeProperty(editor, {
              newName: e.target.value,
            })
          }
        />
      </FormControl>

      <Stack
        gap={2}
        sx={{
          my: 4,
        }}
      >
        <ListOfCompositions editor={editor} category={ComponentCategory.NOTE_DE_TETE} />
        {/* <Divider /> */}
        <ListOfCompositions editor={editor} category={ComponentCategory.NOTE_DE_COEUR} />
        {/* <Divider /> */}
        <ListOfCompositions editor={editor} category={ComponentCategory.NOTE_DE_FOND} />
        <Divider />
        <Box>
          {t("totalVolume")}: {editor.totalVolume}ml
        </Box>
      </Stack>
    </Box>
  );
}

function ListOfCompositions({
  editor,
  category,
}: {
  editor: PerfumeEditor;
  category: ComponentCategory;
}) {
  const components = editor.perfumeViewModel.components.filter((c) => c.category === category);

  const handleAddComponentClick = () => {
    addComponentWithDialog(editor, category);
  };

  const { t } = useTranslation();
  return (
    <>
      <Stack
        sx={{
          alignItems: "flex-start",
          mb: 4,
        }}
      >
        <Typography variant="h6" sx={{ mb: 2 }}>
          <PerfumeCategoryTitle category={category} quantity={components.length} />
        </Typography>
        {components.map((component) => (
          <Composition key={component.componentId} editor={editor} component={component} />
        ))}
        <Button
          color="primary"
          size="small"
          variant="text"
          aria-label="add"
          startIcon={<AddIcon />}
          onClick={handleAddComponentClick}
        >
          {t("ajouterUneNote")} <PerfumeCategoryTitle category={category} quantity={1} noIcon />
        </Button>
      </Stack>
    </>
  );
}

function IngredientButton({
  ingredient,
  onClick,
  sx,
}: {
  ingredient: PerfumeIngredient | null;
  sx?: SxProps<Theme>;
  onClick: () => void;
}) {
  const ingredientDisplayName = useIngredientDiplayName();
  const { t } = useTranslation();
  return ingredient !== null ? (
    <Button
      sx={{ ...sx, textTransform: "none", textAlign: "left" }}
      startIcon={<IngredientPicture ingredient={ingredient} size="icon" />}
      variant="text"
      onClick={onClick}
    >
      {ingredientDisplayName(ingredient)}
    </Button>
  ) : (
    <Button sx={sx} onClick={onClick}>
      {t("chooseAnIngredient")}
    </Button>
  );
}

function Composition({
  editor,
  component,
}: {
  editor: PerfumeEditor;
  component: EditComponentViewModel;
}) {
  const { t } = useTranslation();

  const ingredients = editor.availableIngredients[component.category];

  const openIngredientDialog = () => openComponentIngredientDialog(editor, component.componentId);

  const closeIngredientDialog = () => closeComponentIngredientDialog(editor);

  const ingredientDialogOpen = editor.ingredientDialogOpen?.componentId === component.componentId;

  const handleDeleteComponent = useCallback(() => {
    const confirmationMessage = t("deleteComponentConfirmation");
    if (window.confirm(confirmationMessage)) {
      deleteComponent(editor, component.componentId);
    }
  }, [t, editor, component.componentId]);

  return (
    <>
      <IngredientDialog
        category={component.category}
        availableIngredients={ingredients}
        selectedIngredient={component.ingredient}
        onIngredientChanged={(newIngredient: PerfumeIngredient) => {
          editComponent(editor, component.componentId, {
            ingredient: newIngredient,
          });
        }}
        open={ingredientDialogOpen}
        onClose={closeIngredientDialog}
      />

      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          width: 1,
          maxWidth: "45ch",
          mb: 2,
          gap: 2,
        }}
      >
        <IngredientButton
          ingredient={component.ingredient}
          onClick={openIngredientDialog}
          sx={{
            justifyContent: "flex-start",
            flexGrow: 1,
          }}
        />
        <FormControl>
          <Input
            endAdornment={<InputAdornment position="end">ml</InputAdornment>}
            type="number"
            inputMode="numeric"
            value={component.volume}
            onChange={(e) =>
              editComponent(editor, component.componentId, {
                volume: e.target.value,
              })
            }
            sx={{
              width: {
                xs: "3.5em",
                sm: "4.5em",
              },
            }}
          />
        </FormControl>
        <Button
          color="secondary"
          size="small"
          aria-label="delete"
          variant="text"
          onClick={handleDeleteComponent}
          sx={{
            minWidth: {
              xs: "32px",
            },
          }}
        >
          <DeleteIcon />
        </Button>
        {/* <FormControl>
          <Autocomplete
            disablePortal
            options={ingredients}
            getOptionLabel={(ingredient) =>
              ingredientDisplayName(ingredient.name)
            }
            sx={{
              width: "20ch",
              mr: 1,
            }}
            value={component.ingredient}
            onChange={(_, newValue) =>
              editComponent(editor, {
                component: component,
                ingredient: newValue,
              })
            }
            inputValue={component.displayName}
            onInputChange={(e, newInputValue) =>
              editComponent(editor, {
                component: component,
                displayName: newInputValue,
              })
            }
            renderInput={(params) => (
              <TextField
                {...params}
                label="Nom du composant"
                variant="standard"
              />
            )}
          />
        </FormControl> */}
      </Box>
    </>
  );
}

export default PerfumeComposer;
