export enum PerfumeTunnelPageStepId {
  Identification,
  Parfum,
  Diploma,
}

export function adaptPerfumeTunnelPageStepId(step?: string): PerfumeTunnelPageStepId | null {
  const value = PerfumeTunnelPageStepId[step as keyof typeof PerfumeTunnelPageStepId];
  return typeof value === "number" ? value : null;
}
