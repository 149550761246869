import { type PropsWithChildren, useId, forwardRef, useEffect, type Ref } from "react";
import { useBlocker } from "react-router-dom";
import Dialog from "@mui/material/Dialog";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import AppBar from "@mui/material/AppBar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import Slide from "@mui/material/Slide";
import type { TransitionProps } from "@mui/material/transitions";
import Toolbar from "@mui/material/Toolbar";

export const Transition = forwardRef(function Transition(
  props: TransitionProps & {
    children: JSX.Element;
  },
  ref: Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function BlockNavigation({ unBlock }: { unBlock: () => void }) {
  const blocker = useBlocker(true);
  useEffect(() => {
    if (blocker.state == "blocked") {
      blocker.reset();
      unBlock();
    }
  }, [blocker, unBlock]);

  return false;
}

export function useFullScreenDialog() {
  const theme = useTheme();
  return useMediaQuery(theme.breakpoints.down("md"));
}

export function LargeDialog({
  children,
  title,
  toolbarAction,
  open,
  onClose,
}: PropsWithChildren & {
  title?: JSX.Element;
  toolbarAction?: JSX.Element;
  open: boolean;
  onClose: () => void;
}) {
  const dialogId = useId();

  const fullScreen = useFullScreenDialog();

  return (
    <Dialog
      keepMounted={false}
      TransitionComponent={fullScreen ? Transition : undefined}
      fullScreen={fullScreen}
      fullWidth={true}
      maxWidth="md"
      open={open}
      onClose={onClose}
      aria-labelledby={dialogId}
      PaperProps={{
        sx: {
          height: {
            minHeight: "calc(100% - 64px)",
          },
        },
      }}
    >
      {open && <BlockNavigation unBlock={onClose} />}
      <AppBar position="relative">
        <Toolbar>
          {fullScreen && (
            <IconButton edge="start" color="inherit" onClick={onClose} aria-label="close">
              <CloseIcon />
            </IconButton>
          )}
          {title && (
            <Typography sx={{ flex: 1 }} variant="h6" component="div">
              {title}
            </Typography>
          )}
          {toolbarAction}
        </Toolbar>
      </AppBar>
      {children}
    </Dialog>
  );
}
