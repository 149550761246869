import { SyntheticEvent } from "react";
import { useParams } from "react-router-dom";

export function renderLocalDatetime(date: Date): string {
  return date.toLocaleString();
}

export function dataAttribute(event: SyntheticEvent, name: string): string {
  const element = event.currentTarget;
  if (!(element instanceof HTMLElement)) {
    throw Error("Target must be a HTMLElement");
  }
  const value = element.dataset[name];
  if (value === undefined) {
    throw Error(`Missing attribute ${name} on HTMLElement`);
  }
  return value;
}

export function useRequiredParam(name: string): string {
  const { [name]: value } = useParams();

  if (value === undefined) {
    throw Error(`Missing parameter ${name}`);
  }
  return value;
}
