import App from "./App";
import React from "react";
import { PerfumeTunnelPage } from "@/pages/PerfumeTunnelPage";
import { AccountPage } from "@/pages/AccountPage";
import { PerfumeDetailsPage } from "./pages/PerfumeDetailsPage";
import { ErrorPage } from "@/pages/ErrorPage";
import { HomePage } from "./pages/HomePage";
import { IngredientInventoryPage } from "./pages/IngredientInventoryPage";
import { PathFragment } from "@/models";

import { createRoutesFromElements, Route } from "react-router-dom";

export function appRoutes() {
  return createRoutesFromElements(
    <Route path="/" element={<App />} errorElement={<ErrorPage />}>
      <Route index element={<HomePage />} />
      <Route path={PathFragment.PerfumeWorkshop} element={<PerfumeTunnelPage />} />
      <Route
        path={`${PathFragment.Workshop}/:workshopLocationId/${PathFragment.Organ}/:organId`}
        element={<PerfumeTunnelPage />}
      />
      <Route
        path={`${PathFragment.PerfumeWorkshop}/:perfumeId/:step?`}
        element={<PerfumeTunnelPage />}
      />
      <Route path={PathFragment.Account} element={<AccountPage />} />
      <Route
        path={`${PathFragment.Admin}/${PathFragment.Perfumes}`}
        element={
          <React.Suspense fallback={<>...</>}>
            <PerfumeListPage />
          </React.Suspense>
        }
      />
      <Route
        path={`${PathFragment.Admin}/${PathFragment.IngredientInventory}`}
        element={<IngredientInventoryPage />}
      />
      <Route path={`${PathFragment.Perfumes}/:perfumeId`} element={<PerfumeDetailsPage />} />
    </Route>,
  );
}

const PerfumeListPage = React.lazy(() => import("./pages/PerfumeListPage"));
