import type { EffectCallback } from "react";
import { useEffect, useRef } from "react";

export function useEffectOnceInStrictMode(effect: EffectCallback): void {
  const initialized = useRef<EffectCallback | null>(null);

  useEffect(() => {
    if (initialized.current !== effect) {
      initialized.current = effect;
      effect();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
}
