import { GalimardIngredient } from "./GalimardIngredient";

export const GalimardNoteDeTete: GalimardIngredient[] = [
  {
    name: "ANANAS",
    galimardReference: "901",
    description: {
      fr: 'Ananas est un fruit tropical sucré et juteux, également connu sous le nom de "roi des fruits". Il se distingue par son arôme doux et parfumé qui est à la fois rafraîchissant et exotique.',
      en: 'Ananas is a sweet and juicy tropical fruit, also known as the "king of fruits." It is distinguished by its sweet and fragrant aroma, which is both refreshing and exotic.',
    },
    advice: `Elle évoque des sentiments de positivité et de bonne humeur.`,
    icon: "ananas",
  },
  {
    name: "ANIS",
    galimardReference: "??",
    description: { fr: ``, en: "" },
    advice: ``,
    icon: "anis",
  },
  {
    name: "BERGAMOTE",
    galimardReference: "676",
    description: {
      fr: `L'arôme frais et citronné de la bergamote est souvent utilisé pour ouvrir une composition.`,
      en: "",
    },
    advice: `Elle crée une impression de pureté et de vivacité`,
    icon: "bergamote",
  },
  {
    name: "BERGAMOTE VERTE",
    galimardReference: "602",
    description: {
      fr: `La bergamote verte est une fragrance fraîche et vivifiante, qui évoque l'odeur des feuilles et des fleurs d'agrumes.`,
      en: "",
    },
    advice: `Elle est souvent associée à d'autres notes florales, comme le la rose.`,
    icon: "bergamote-verte",
  },
  {
    name: "BOUTON DE ROSE",
    displayName: {
      fr: "Bouton de Rose",
      en: "",
    },
    galimardReference: "677",
    description: {
      fr: `L'odeur de la fragrance bouton de rose est fraîche et florale, avec une touche de douceur.`,
      en: "",
    },
    advice: `Elle peut être portée au quotidien pour une touche de sophistication.`,
    icon: `bouton-de-rose`,
  },
  {
    name: "CARDAMOME",
    galimardReference: "970",
    description: {
      fr: `La cardamome est une fragrance qui éveille les sens. Elle est chaude et épicée, avec une note balsamique.`,
      en: "",
    },
    advice: `Elle peut être associée à des notes florales, fruitées ou boisées.`,
    icon: "cardamome",
  },
  {
    name: "CITRON AMER",
    displayName: {
      fr: "Citron Amer",
      en: "",
    },
    galimardReference: "678",
    description: {
      fr: `Le citron amer est une fragrance fraîche et vivifiante, qui évoque les beaux jours.`,
      en: "",
    },
    advice: `La fragrance citron amer est idéale pour les personnes qui recherchent une fragrance fraîche et énergisante.`,
    icon: "citron-amer",
  },
  {
    name: "CITRON DOUX",
    galimardReference: "679",
    description: {
      fr: `Le citron doux est une fragrance fraîche et vivifiante qui évoque les fruits juteux et les journées ensoleillées.`,
      en: "",
    },
    advice: `Le citron doux est une fragrance polyvalente qui peut être portée en toute occasion.`,
    icon: "citron-doux",
  },
  {
    name: "CITRON ZESTE",
    galimardReference: "680",
    description: {
      fr: `Le zeste de citron est une note de tête incontournable de la parfumerie. Elle est associée à la fraîcheur, à la tonicité et à la luminosité.`,
      en: "",
    },
    advice: `Le zeste de citron est une note versatile, unisexes qui se caractérise par son acidité.`,
    icon: "citron-zeste",
  },
  {
    name: "COMPLEXE VERT",
    galimardReference: "605",
    description: { fr: ``, en: "" },
    advice: ``,
    icon: "complexe-vert",
  },
  {
    name: "CONCOMBRE",
    galimardReference: "954",
    description: { fr: ``, en: "" },
    advice: ``,
    icon: "concombre",
  },
  {
    name: "FEUILLES VERTES",
    galimardReference: "606",
    description: { fr: ``, en: "" },
    advice: ``,
    icon: "feuilles-vertes",
  },
  {
    name: "FLEUR ORANGER",
    displayName: {
      fr: "Fleur d'Oranger Murcia",
      en: "",
    },
    galimardReference: "347",
    description: { fr: ``, en: "" },
    advice: ``,
    icon: "fleur-oranger",
  },
  {
    name: "FLORALE FRAICHE",
    displayName: {
      fr: "Florale Fraîche",
      en: "",
    },
    galimardReference: "607",
    description: { fr: ``, en: "" },
    advice: ``,
    icon: "florale-fraiche",
  },
  {
    name: "FRUIT DE BERGAMOTE",
    displayName: {
      fr: "Fruit de Bergamote",
      en: "",
    },
    galimardReference: "608",
    description: { fr: ``, en: "" },
    advice: ``,
    icon: "fruit-bergamote",
  },
  {
    name: "FRUITS DE CASSIS",
    displayName: {
      fr: "Fruit de Cassis",
      en: "",
    },
    galimardReference: "505",
    description: { fr: ``, en: "" },
    advice: ``,
    icon: "fruits-decassis",
  },
  {
    name: "FRUITS VERTS",
    galimardReference: "609",
    description: { fr: ``, en: "" },
    advice: ``,
    icon: "fruits-verts",
  },
  {
    name: "GALBANUM VERT",
    galimardReference: "683",
    description: { fr: ``, en: "" },
    advice: ``,
    icon: "galbanum-vert",
  },
  {
    name: "GERANIUM SAUVAGE",
    displayName: {
      fr: "Géranium Sauvage",
      en: "",
    },
    galimardReference: "610",
    description: { fr: ``, en: "" },
    advice: ``,
    icon: "geranium-sauvage",
  },
  {
    name: "HERBE FRAICHE",
    displayName: {
      fr: "Herbe Fraîche",
      en: "",
    },
    galimardReference: "612",
    description: { fr: ``, en: "" },
    advice: ``,
    icon: "herbe-fraiche",
  },
  {
    name: "JASMIN ORIENTAL",
    galimardReference: "614",
    description: { fr: ``, en: "" },
    advice: ``,
    icon: "jasmin-oriental",
  },
  {
    name: "LAVANDE DES ALPES",
    displayName: {
      fr: "Lavande des Alpes",
      en: "",
    },
    galimardReference: "697",
    description: { fr: ``, en: "" },
    advice: ``,
    icon: "lavande-des-alpes",
  },
  {
    name: "LAVANDE SAUVAGE",
    galimardReference: "615",
    description: { fr: ``, en: "" },
    advice: ``,
    icon: "lavande-sauvage",
  },
  {
    name: "LIMETTE VERTE",
    galimardReference: "686",
    description: { fr: ``, en: "" },
    advice: ``,
    icon: "limette-verte",
  },
  {
    name: "LITCHEE",
    galimardReference: "925",
    description: { fr: ``, en: "" },
    advice: ``,
    icon: "litchee",
  },
  {
    name: "LOTUS",
    galimardReference: "921",
    description: { fr: ``, en: "" },
    advice: ``,
    icon: "lotus",
  },
  {
    name: "MANDARINE",
    galimardReference: "687",
    description: { fr: ``, en: "" },
    advice: ``,
    icon: "mandarine",
  },
  {
    name: "MANDARINE DOUCE",
    galimardReference: "166",
    description: { fr: ``, en: "" },
    advice: ``,
    icon: "mandarine-douce",
  },
  {
    name: "MANGUE",
    galimardReference: "908",
    description: { fr: ``, en: "" },
    advice: ``,
    icon: "mangue",
  },
  {
    name: "NOTE HESPERIDEE",
    displayName: {
      fr: "Note Hespéridée",
      en: "",
    },
    galimardReference: "688",
    description: { fr: ``, en: "" },
    advice: ``,
    icon: "note-hesperidee",
  },
  {
    name: "NOTE VERTE",
    galimardReference: "617",
    description: { fr: ``, en: "" },
    advice: ``,
    icon: "note-verte",
  },
  {
    name: "ORANGER",
    galimardReference: "619",
    description: { fr: ``, en: "" },
    advice: ``,
    icon: "oranger",
  },
  {
    name: "PAMPLEMOUSSE",
    galimardReference: "122",
    description: { fr: ``, en: "" },
    advice: ``,
    icon: "pamplemousse",
  },
  {
    name: "PECHE BLANCHE",
    displayName: {
      fr: "Pêche blanche",
      en: "",
    },
    galimardReference: "912",
    description: { fr: ``, en: "" },
    advice: ``,
    icon: "peche-blanche",
  },
  {
    name: "POIRE",
    displayName: {
      fr: "Poire sorbet",
      en: "",
    },
    galimardReference: "956",
    description: { fr: ``, en: "" },
    advice: ``,
    icon: "poire",
  },
  {
    name: "POIVRE ROSE",
    galimardReference: "578",
    description: { fr: ``, en: "" },
    advice: ``,
    icon: "poivre-rose",
  },
  {
    name: "ROSE DE MAI",
    displayName: {
      fr: "Rose de mai",
      en: "",
    },
    galimardReference: "622",
    description: { fr: ``, en: "" },
    advice: ``,
    // prompt: "bouquet of Rosa Centifolia, detailed, white background"
    icon: "rose-de-mai",
  },
  {
    name: "ROSE FRUITEE",
    displayName: {
      fr: "Rose Fruitée",
      en: "",
    },
    galimardReference: "623",
    description: { fr: ``, en: "" },
    advice: ``,
    icon: "rose-fruitee",
  },
  {
    name: "SAUGE SCLAREE",
    displayName: {
      fr: "Sauge Sclarée",
      en: "",
    },
    galimardReference: "722",
    description: { fr: ``, en: "" },
    advice: ``,
    icon: "sauge-sclaree",
  },
  {
    name: "SECRET DU PARFUMEUR",
    displayName: {
      fr: "Secret du parfumeur",
      en: "",
    },
    galimardReference: "749",
    description: { fr: ``, en: "" },
    advice: ``,
    // prompt: "secret scent fragrance, detailed, white background"
    icon: "secret-du-parfumeur",
  },
  {
    name: "POMME",
    galimardReference: "??",
    description: { fr: ``, en: "" },
    advice: ``,
    icon: "pomme",
  },
  {
    name: "THE ANGLAIS",
    displayName: {
      fr: "Thé Anglais",
      en: "",
    },
    galimardReference: "931",
    description: { fr: ``, en: "" },
    advice: ``,
    icon: "the-anglais",
  },
  {
    name: "THE VERT",
    displayName: {
      fr: "Thé Vert",
      en: "",
    },
    galimardReference: "932",
    description: { fr: ``, en: "" },
    advice: ``,
    // prompt: "green tea, glass cup, detailed, white background"
    icon: "the-vert",
  },
  {
    name: "TOMATE",
    galimardReference: "916",
    description: { fr: ``, en: "" },
    advice: ``,
    // prompt: "sliced tomato on a small wooden plank, detailed, white background"
    icon: "tomate",
  },
  {
    name: "VERVEINE",
    galimardReference: "689",
    description: { fr: ``, en: "" },
    advice: ``,
    // prompt: "verbena verveine, detailed, white background"
    icon: "verveine",
  },
  {
    name: "YUZU",
    galimardReference: "968",
    description: { fr: ``, en: "" },
    advice: ``,
    icon: "yuzu",
  },
];
