import { GalimardIngredient } from "./GalimardIngredient";

export const GalimardNoteDeFond: GalimardIngredient[] = [
  {
    name: "ACCORD MUSC",
    displayName: {
      fr: "Accord Musc",
      en: "",
    },
    galimardReference: "705",
    icon: "accord-musc",
  },
  {
    name: "AMANDE",
    galimardReference: "900",
    icon: "amande",
  },
  {
    name: "AMBRE",
    galimardReference: "650",
    // prompt: "small amber rocks on a small wooden plank, detailed, white background"
    icon: "ambre",
  },
  {
    name: "AMBRE ORIENTAL",
    galimardReference: "247",
    // prompt: "a few amber rocks with burning incense sticks on a small wooden plank, detailed, white background"
    icon: "ambre-oriental",
  },
  {
    name: "AMBRE VERT",
    galimardReference: "652",
    // prompt: "a few small amber rocks and wood moss on a small wooden plank, detailed, white background"
    icon: "ambre-vert",
  },
  {
    name: "AMBREINE",
    displayName: {
      fr: "Ambréine",
      en: "",
    },
    galimardReference: "653",
    icon: "ambreine",
  },
  {
    name: "BOIS AMBRE",
    displayName: {
      fr: "Bois Ambré",
      en: "",
    },
    galimardReference: "654",
    icon: "bois-ambre",
  },
  {
    name: "BOIS DE SANTAL",
    displayName: {
      fr: "Bois de Santal",
      en: "",
    },
    galimardReference: "655",
    icon: "bois-de-santal",
  },
  {
    name: "COMPLEXE BOISE",
    displayName: {
      fr: "Complexe Boisé",
      en: "",
    },
    galimardReference: "661",
    icon: "complexe-boise",
  },
  {
    name: "BOIS ET TONKA",
    displayName: {
      fr: "bois et Tonka",
      en: "",
    },
    galimardReference: "723",
    icon: "bois-et-tonka",
  },
  {
    name: "BOIS MOUSSE",
    galimardReference: "656",
    icon: "bois-mousse",
  },
  {
    name: "BOISE CEDRE",
    displayName: {
      fr: "Boisé cèdre",
      en: "",
    },
    galimardReference: "659",
    icon: "boise-cedre",
  },
  {
    name: "BOUQUET BOISE",
    displayName: {
      fr: "Bouquet Boisé",
      en: "",
    },
    galimardReference: "706",
    // prompt: "bouquet boisé lying down, top view, detailed, white background"
    icon: "bouquet-boise",
  },
  {
    name: "CHOCOLAT",
    displayName: {
      fr: "Chocolat",
      en: "",
    },
    galimardReference: "",
    icon: "chocolat",
  },
  // {
  //   name: "CONCRETAL",
  //   displayName: {
  //     fr: "Contrétal",
  //     en: "",
  //   },
  //   galimardReference: "477",
  //   // icon: "concretal",
  // },
  {
    name: "CUIR",
    galimardReference: "458",
    // prompt: "leather gloves on top on each other on a small table with a ancien perfume spray, detailed, white background"
    icon: "cuir",
  },
  {
    name: "ECORCE",
    displayName: {
      fr: "Écorce",
      en: "",
    },
    galimardReference: "707",
    icon: "ecorce",
  },
  {
    name: "FEVE TONKA",
    displayName: {
      fr: "Fève Tonka",
      en: "",
    },
    galimardReference: "662",
    // prompt: "longues fève noires ridée on a small wooden plank, detailed, white background"
    icon: "feve-tonka",
  },
  {
    name: "FLEUR DE MUGUET",
    displayName: {
      fr: "Fleur de muguet",
      en: "",
    },
    galimardReference: "708",
    // prompt: "bouquet of lily of the valley in bloom lying down, top view, detailed, white background"
    icon: "fleur-de-muguet",
  },
  {
    name: "FRUITS SECS",
    galimardReference: "710",
    // prompt: "dried fruit  on a small wooden plank, detailed, white background"
    icon: "fruits-secs",
  },
  {
    name: "IRIS",
    galimardReference: "663",
    icon: "iris",
  },
  {
    name: "LICHEN",
    galimardReference: "664",
    // prompt: "lichen on a granite rock, detailed, white background"
    icon: "lichen",
  },
  {
    name: "LILAS",
    galimardReference: "665",
    // prompt: "bouquet of lila lying down, top view, detailed, white background"
    icon: "lilas",
  },
  {
    name: "MOUSSE",
    galimardReference: "711",
    icon: "mousse",
  },
  {
    name: "MOUSSE D'ARBRE",
    displayName: {
      fr: "Mousse d'arbre",
      en: "",
    },
    galimardReference: "712",
    // prompt: "tree moss on a trunk detailed, white background"
    icon: "mousse-arbre",
  },
  {
    name: "MOUSSE DE BAVIERE",
    displayName: {
      fr: "Mousse de Bavière",
      en: "",
    },
    galimardReference: "478",
    // tree moss in Bavaria on a trunk detailed, white background
    icon: "mousse-de-baviere",
  },
  {
    name: "MOUSSE VERTE",
    galimardReference: "713",
    // prompt: "close-up of a rock covered with green moss"
    icon: "mousse-verte",
  },
  {
    name: "MUSC",
    // prompt: "happy musk deer, ears pointing up, detailed, white background"
    galimardReference: "666",
    icon: "musc",
  },
  {
    name: "MUSC BLANC",
    galimardReference: "715",
    // prompt: "musk deer walking in the forest, a sheet is drying in the background, clean, detailed, white background"
    icon: "musc-blanc",
  },
  {
    name: "MUSC DOUX",
    // prompt: "happy musk deer without wood, detailed, white background"
    galimardReference: "667",
    icon: "musc-doux",
  },
  {
    name: "MUSC FLORAL",
    // prompt: "musk deer, walking on flowers, detailed, white background"
    galimardReference: "668",
    icon: "musc-floral",
  },
  {
    name: "NOTE PRALINE",
    galimardReference: "504",
    // prompt: "bowl of brown pralines shaped as almonds in cooked sugar placed on a small wooden plank, detailed, white background"
    icon: "note-praline",
  },
  {
    name: "NOTE CASTOREUM",
    galimardReference: "669",
    // icon: "note castoreum",
  },
  {
    name: "VETIVER",
    galimardReference: "675",
    displayName: {
      fr: "Vétiver",
      en: "",
    },
    // prompt: "Vetivergras, detailed, white background"
    icon: "vetiver",
  },
  {
    name: "SOUS BOIS",
    galimardReference: "719",
    icon: "sous-bois",
  },
  {
    name: "RACINES DE VETIVER",
    displayName: {
      fr: "Racines de Vétiver",
      en: "",
    },
    galimardReference: "716",
    // prompt: "vetiver with it's roots, detailed, white background"
    icon: "racines-de-vetiver",
  },
  {
    name: "PATCHOULI",
    galimardReference: "390",
    // prompt: "patchouli, detailed, white background"
    icon: "patchouli",
  },
  {
    name: "OUD",
    galimardReference: "???",
    description: { fr: ``, en: "" },
    advice: ``,
    icon: "oud",
  },
  {
    name: "OPOPONAX",
    galimardReference: "806",
    icon: "opoponax",
  },
  {
    name: "SANTAL",
    galimardReference: "671",
    icon: "santal",
  },
  {
    name: "SANTAL EXOTIQUE",
    galimardReference: "717",
    icon: "santal-exotique",
  },
  {
    name: "SANTAL ORIENT",
    displayName: {
      fr: "Santal d'Orient",
      en: "",
    },
    galimardReference: "672",
    icon: "santal-orient",
  },
  {
    name: "SANTALINE",
    galimardReference: "718",
    icon: "santaline",
  },
  {
    name: "TUBEREUSE",
    displayName: {
      fr: "Tubéreuse",
      en: "",
    },
    galimardReference: "720",
    icon: "tubereuse",
  },
  {
    name: "VANILLE",
    galimardReference: "198",
    icon: "vanille",
  },
  {
    name: "CEDRE EBENE",
    displayName: {
      fr: "Cèdre ébène",
      en: "",
    },
    galimardReference: "???",
    icon: "cedre-ebene",
  },
];
