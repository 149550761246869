import { useCallback, useEffect, useState } from "react";
import DelayedLinearProgress from "@/components/DelayedLinearProgress";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import Alert from "@mui/material/Alert";
import { services } from "@/injectServices";
import { useAuthentication, useSignIn } from "@/services";
import { useSearchParams } from "react-router-dom";
import { SignInError } from "@/models";

export function AccountPage() {
  useEffect(() => {
    document.title = `Galimard Workshop: Account`;
  });

  const [searchParams] = useSearchParams();
  const [errorMessage, setErrorMessage] = useState<string>("");

  const { user, authLoading, canLogin } = useAuthentication(services().authenticationService);

  const { signIn, signOut } = useSignIn(
    services().authenticationService,
    searchParams.get("redirect"),
  );

  const handleLogin = useCallback(async () => {
    try {
      await signIn();
    } catch (error) {
      if (error instanceof SignInError) {
        setErrorMessage(`${error.message}: ${error.from.message}`);
        throw error.from;
      }
    }
  }, [signIn]);

  return (
    <Container>
      <Paper
        sx={{
          m: 1,
          p: 2,
        }}
      >
        <DelayedLinearProgress loading={authLoading} />

        <Typography variant="h4">Account</Typography>

        {canLogin && (
          <Button onClick={handleLogin} variant="contained" sx={{ m: 2 }}>
            Sign in using Google
          </Button>
        )}
        {user !== null && (
          <>
            <Typography variant="subtitle1">{user.displayName}</Typography>
            <Typography variant="subtitle2">{user.email}</Typography>
            {user.role && <Typography variant="body1">Role: {user.role}</Typography>}
            <Button onClick={signOut} color="secondary" variant="contained" sx={{ m: 2 }}>
              Sign Out
            </Button>
          </>
        )}
        {errorMessage && <Alert severity="error">{errorMessage}</Alert>}
      </Paper>
    </Container>
  );
}
