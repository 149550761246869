import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

const englishL18n = {
  firstName: "First name",
  lastName: "Last name",
  title: "Title",
  title_01: "Mr.",
  title_02: "Mrs.",
  title_03: "Ms.",
  title_04: "Mx.",
  perfumeName: "Perfum's name",
  noteDeTete: "Head note",
  notesDeTete: "Head notes",
  noteDeCoeur: "Heart note",
  notesDeCoeur: "Heart notes",
  noteDeFond: "Base note",
  notesDeFond: "Base notes",
  atelierDeCreation: "Galimard perfume creation workshop",
  creerUnNouveauParfum: "Create a new perfume",
  identificationStep: "Identification",
  perfumeStep: "Perfume",
  diplomaStep: "Diploma",
  chooseAnIngredient: "Choose an ingredient",
  ajouterUneNote: "Add a",
  totalVolume: "Total volume",
  navNext: "Next",
  navBack: "Back",
  navLastStep: "Done",
  summaryCreated: "Creation in workshop on {{date}}",
  summaryLocation: "at {{location}}",
  summaryOrgan: "on the perfume organ {{organ}}",
  summaryFormulaNumber: "Formula number :",
  summaryComponentsVolume: "This perfume has {{numberOfComponents}} components for {{volume}} ml",
  showDiploma: "Show diploma",
  deleteComponentConfirmation: "Are you sure you want to delete this note?",
};

const frenchL18N: typeof englishL18n = {
  firstName: "Prénom",
  lastName: "Nom",
  title: "Titre",
  title_01: "Monsieur",
  title_02: "Madame",
  title_03: "",
  title_04: "",
  perfumeName: "Nom du parfum",
  noteDeTete: "Note de tête",
  notesDeTete: "Notes de tête",
  noteDeCoeur: "Note de cœur",
  notesDeCoeur: "Notes de cœur",
  noteDeFond: "Note de fond",
  notesDeFond: "Notes de fond",
  atelierDeCreation: "Atelier de création de parfum Galimard",
  creerUnNouveauParfum: "Créer un nouveau parfum",
  identificationStep: "Identification",
  perfumeStep: "Parfum",
  diplomaStep: "Diplôme",
  chooseAnIngredient: "Choisissez un ingredient",
  ajouterUneNote: "Ajouter une",
  totalVolume: "Volume total",
  navNext: "Suivant",
  navBack: "Retour",
  navLastStep: "Terminer",
  summaryCreated: "Création en atelier le {{date}}",
  summaryLocation: "au {{location}}",
  summaryOrgan: "sur l'orgue à parfum {{organ}}",
  summaryFormulaNumber: "Numéro de formule :",
  summaryComponentsVolume:
    "Ce parfum comporte {{numberOfComponents}} composants pour {{volume}} ml",
  showDiploma: "Voir le diplôme",
  deleteComponentConfirmation: "Voulez-vous enlever cette note ?",
};

declare module "i18next" {
  // Extend CustomTypeOptions
  interface CustomTypeOptions {
    // custom namespace type, if you changed it
    defaultNS: "translation";
    // custom resources type
    resources: {
      translation: typeof englishL18n;
    };
  }
}

export function initializeI18n() {
  i18next
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
      fallbackLng: "fr",
      debug: false,
      interpolation: {
        escapeValue: false,
      },
      resources: {
        en: {
          translation: englishL18n,
        },
        fr: {
          translation: frenchL18N,
        },
      },
    });
}
