import type { Perfume, PerfumeSummary } from "./Perfume";

function _isPerfumeEmpty(perfume: Perfume | PerfumeSummary): number {
  return (
    perfume.name.trim().length +
    perfume.email.trim().length +
    perfume.username.trim().length +
    perfume.userLastName.trim().length
  );
}

export function isPerfumeEmpty(perfume: Perfume): boolean {
  const nonEmptyComponents = perfume.components.filter((c) => c.name.length > 0);

  const numberOfThings = _isPerfumeEmpty(perfume) + nonEmptyComponents.length;
  return numberOfThings === 0;
}

export function isPerfumeSummaryEmpty(perfume: PerfumeSummary): boolean {
  return _isPerfumeEmpty(perfume) === 0;
}
