import { useMemo, useState, useCallback, useEffect } from "react";
import { v4 as uuidv4 } from "uuid";
import { useNavigate } from "react-router-dom";
import {
  type Perfume,
  AvailablePerfumeIngredients,
  perfumeVolume,
  PageLink,
  adaptPerfumeOrgan,
  adaptPerfumeWorkshopLocation,
  buildIngredients,
  type CategoriesOfIngredients,
  PerfumeTunnelPageStepId,
} from "@/models";
import { debounce } from "@mui/material/utils";
import { showLoader, type LoadingDispatcher } from "@/hooks/Loading";
import { PerfumeConverter } from "@/repositories/PerfumeDb";
import { type PerfumeRecipiesService } from "./PerfumeRecipiesService";
import type { IngredientDialogOpen, PerfumeEditor } from "./perfume-editor";
import type { EditPerfumeViewModel } from "./perfume-editor/EditPerfumeViewModel";
import { adaptEditPerfumeViewModel, adaptPerfume } from "./perfume-editor/EditPerfumeViewModel";
import { useEffectOnceInStrictMode } from "@/hooks/useEffectOnceInStrictMode";

export function usePerfumeEditor({
  service,
  backendPerfume,
}: {
  service: PerfumeRecipiesService;
  backendPerfume: Perfume;
}): PerfumeEditor {
  const [ingredientDialogOpen, setIngredientDialogOpen] = useState<IngredientDialogOpen>(null);

  const [perfumeViewModel, setPerfumeViewModel] = useState<EditPerfumeViewModel>(
    adaptEditPerfumeViewModel(backendPerfume),
  );

  // update viewmodel when backend perfume change. Occurs on
  // - change from outside (another tab or computer)
  // - after saving
  useEffect(() => {
    setPerfumeViewModel((prev: EditPerfumeViewModel) => {
      // no need to update viewmodel there is no change with backendPerfume
      const vmPerfume = adaptPerfume(backendPerfume, prev);
      if (perfumeDeepEqual(vmPerfume, backendPerfume)) {
        return prev;
      }
      return adaptEditPerfumeViewModel(backendPerfume);
    });
  }, [backendPerfume]);

  const savePerfume = useMemo(() => {
    return debounce((updatedPerfume: Perfume) => {
      service.perfumeRepository.savePerfume(updatedPerfume);
    }, 1500);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // save perfume when user change it (by calling setPerfumeViewModel)
  useEffect(() => {
    const updatedPerfume = adaptPerfume(backendPerfume, perfumeViewModel);
    if (perfumeDeepEqual(updatedPerfume, backendPerfume)) {
      return;
    }

    return savePerfume(updatedPerfume);
    // 'backendPerfume' intentionally not included
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [perfumeViewModel, savePerfume]);

  const ingredients: CategoriesOfIngredients = useMemo(() => {
    return buildIngredients({
      ingredients: AvailablePerfumeIngredients,
      additionalIngredients: perfumeViewModel.customIngredients,
    });
  }, [perfumeViewModel.customIngredients]);

  return useMemo(
    (): PerfumeEditor => ({
      updatePerfumeViewModel: setPerfumeViewModel,
      ingredientDialogOpen,
      setIngredientDialogOpen,
      availableIngredients: ingredients,
      perfumeViewModel,
      totalVolume: perfumeVolume(backendPerfume),
    }),
    [setPerfumeViewModel, ingredientDialogOpen, ingredients, perfumeViewModel, backendPerfume],
  );
}

function deepEqual<T>(a: T, b: T): boolean {
  return JSON.stringify(a) === JSON.stringify(b);
}

function perfumeDeepEqual(l: Perfume, r: Perfume): boolean {
  return (
    l.recipeId === r.recipeId &&
    deepEqual(PerfumeConverter.toFirestore(l), PerfumeConverter.toFirestore(r))
  );
}

export function createPerfume({
  service,
  loadingDispatcher,
  workshopLocationId,
  organId,
}: {
  service: PerfumeRecipiesService;
  loadingDispatcher: LoadingDispatcher;
  workshopLocationId: string;
  organId: string;
}) {
  console.log(`Creating new perfume for workshop ${workshopLocationId} and organ ${organId}`);

  const workshop = adaptPerfumeWorkshopLocation(workshopLocationId);
  if (workshop === null) {
    throw Error(`Unknown workshop: ${workshopLocationId}`);
  }

  const organ = adaptPerfumeOrgan(organId);

  return showLoader(
    loadingDispatcher,
    service.perfumeRepository.createPerfume(
      {
        recipeId: uuidv4(),
        formulaNumber: null,
        created: new Date(),
        name: "",
        email: "",
        title: "",
        username: "",
        userLastName: "",
        components: [],
        workshopLocation: workshop,
        perfumeOrgan: organ,
      },
      workshop.formulaNumberPrefix,
    ),
  );
}

export function useCreatePerfume({
  service,
  loadingDispatcher,
  workshopLocationId,
  organId,
  perfumeId,
  create,
}: {
  service: PerfumeRecipiesService;
  loadingDispatcher: LoadingDispatcher;
  workshopLocationId: string | null;
  organId: string | null;
  perfumeId: string | null;
  create: boolean;
}) {
  const navigate = useNavigate();

  const [cantCreatePerfumeError, setCantCreatePerfumeError] = useState<string>("");

  useEffectOnceInStrictMode(
    useCallback(() => {
      // create new perfume when no recipeId is provided
      (async function () {
        if (perfumeId !== null) {
          return;
        }
        let navigateToPerfume: Perfume | null = null;

        if (workshopLocationId === null || organId === null) {
          setCantCreatePerfumeError("Please use a QR code to create a perfume");
          return;
        }

        if (!create) {
          const organHistory = await service.perfumeRepository.listPerfumeOfOrgan({
            organId,
            limit: 1,
          });

          if (organHistory.length > 0) {
            const lastPerfumeFromHistory = organHistory[organHistory.length - 1];

            if (lastPerfumeFromHistory !== null) {
              navigateToPerfume = lastPerfumeFromHistory;
            }
          }
        }

        if (navigateToPerfume === null) {
          navigateToPerfume = await createPerfume({
            service,
            loadingDispatcher,
            workshopLocationId,
            organId,
          });
        }

        navigate(
          PageLink.perfumeWorkshop({
            perfumeId: navigateToPerfume.recipeId,
            step: PerfumeTunnelPageStepId.Identification,
          }),
          {
            replace: true,
          },
        );
      })();
    }, [perfumeId, workshopLocationId, organId, create, service, navigate, loadingDispatcher]),
  );

  return {
    cantCreatePerfumeError,
  };
}

export function useCreateNewPerfume({
  service,
  loadingDispatcher,
  workshopLocationId,
  organId,
}: {
  service: PerfumeRecipiesService;
  loadingDispatcher: LoadingDispatcher;
  workshopLocationId?: string;
  organId?: string;
}) {
  const navigate = useNavigate();

  const canCreateNewPerfume = workshopLocationId && organId;

  const createNewPerfume = async () => {
    if (canCreateNewPerfume) {
      const createdPerfume = await createPerfume({
        service,
        loadingDispatcher,
        workshopLocationId,
        organId,
      });

      navigate(PageLink.perfumeWorkshop({ perfumeId: createdPerfume.recipeId }));
    }
  };

  return {
    createNewPerfume,
    canCreateNewPerfume,
  };
}
