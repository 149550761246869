import { type Perfume, ComponentCategory } from "@/models";
import { adaptPerfumeWorkshopLocation, adaptPerfumeOrgan } from "@/models/WorkshopLocationAndOrgan";
import {
  Timestamp,
  type FirestoreDataConverter,
  type QueryDocumentSnapshot,
} from "firebase/firestore";

function adaptPerfumeComponentCategory(db: string): ComponentCategory {
  const category = ComponentCategory[db as keyof typeof ComponentCategory];
  if (category === undefined) {
    throw new Error(`Unknown ComponentCategory: ${db}`);
  }
  return category;
}

export interface PerfumeComponentDb {
  name: string;
  volume: number;
  category: string;
}

export interface PerfumeDb {
  formulaNumber: string;
  created: Timestamp;
  name: string;
  email: string;
  title?: string;
  username?: string;
  userLastName?: string;
  components: PerfumeComponentDb[];
  workshopLocationId: string;
  perfumeOrganId: string;
}

export type KeyOfT<T extends object> = Extract<keyof T, string>;

export function keyOf<T extends object>(field: KeyOfT<T>): KeyOfT<T> {
  return field;
}

export const PerfumeConverter: FirestoreDataConverter<Perfume, PerfumeDb> = {
  toFirestore: (perfume: Perfume): PerfumeDb => {
    return {
      formulaNumber: perfume.formulaNumber ?? "",
      created: Timestamp.fromDate(perfume.created),
      name: perfume.name,
      email: perfume.email,
      title: perfume.title,
      username: perfume.username,
      userLastName: perfume.userLastName,
      workshopLocationId: perfume.workshopLocation?.workshopLocationId ?? "",
      perfumeOrganId: perfume.perfumeOrgan?.perfumeOrganId ?? "",
      components: perfume.components.map((component) => {
        return {
          name: component.name,
          volume: component.volume,
          category: ComponentCategory[component.category],
        };
      }),
    };
  },
  fromFirestore: function (snapshot: QueryDocumentSnapshot<PerfumeDb>): Perfume {
    const db = snapshot.data();
    return {
      recipeId: snapshot.id,
      formulaNumber: db.formulaNumber ? db.formulaNumber : null,
      created: db.created.toDate(),
      name: db.name,
      email: db.email,
      title: db.title || "",
      username: db.username || "",
      userLastName: db.userLastName || "",
      workshopLocation: adaptPerfumeWorkshopLocation(db.workshopLocationId),
      perfumeOrgan: adaptPerfumeOrgan(db.perfumeOrganId),
      components: db.components.map((component: PerfumeComponentDb) => {
        return {
          name: component.name,
          volume: component.volume,
          category: adaptPerfumeComponentCategory(component.category),
        };
      }),
    };
  },
};
