import "./App.css";
import { Outlet } from "react-router-dom";
import CssBaseline from "@mui/material/CssBaseline";
import { MainAppBar } from "@/components";
import { AuthenticatedUserContext, useAuthentication } from "@/services";
import { services } from "./injectServices";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { pink, purple, red } from "@mui/material/colors";

declare module "@mui/material/styles" {
  interface Theme {
    status: {
      danger: string;
    };
  }
  // allow configuration using `createTheme`
  interface ThemeOptions {
    status?: {
      danger?: string;
    };
  }

  interface Palette {
    menu: Palette["primary"];
    danger: Palette["error"];
  }

  interface PaletteOptions {
    menu?: PaletteOptions["primary"];
    danger: PaletteOptions["error"];
  }
}

declare module "@mui/material/Button" {
  interface ButtonPropsColorOverrides {
    menu: true;
    danger: true;
  }
}

function App() {
  const { user } = useAuthentication(services().authenticationService);

  const theme = createTheme({
    palette: {
      menu: {
        main: "rgb(47, 47, 47)",
      },
      primary: {
        // main: "#e2bbbb",
        main: pink[200],
      },
      text: {
        primary: "rgb(47, 47, 47)",
      },
      secondary: {
        // main: "#b28486",
        main: purple[400],
      },
      danger: {
        main: red[200],
      },
    },
  });

  return (
    <>
      <AuthenticatedUserContext.Provider value={user}>
        <CssBaseline />
        <ThemeProvider theme={theme}>
          <MainAppBar />
          <Outlet />
        </ThemeProvider>
      </AuthenticatedUserContext.Provider>
    </>
  );
}

export default App;
