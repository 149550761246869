export interface PerfumeOrgan {
  perfumeOrganId: string;
  name: string;
}

export interface WorkshopLocation {
  workshopLocationId: string;
  name: string;
  formulaNumberPrefix: string;
  address: string;
  perfumeOrgans: PerfumeOrgan[];
}

export const availableWorkshopLocations: WorkshopLocation[] = [
  {
    workshopLocationId: "YesPara",
    name: "Paris",
    formulaNumberPrefix: "GP",
    address: "43 rue de Provence, 75009 Paris",
    perfumeOrgans: [
      {
        perfumeOrganId: "1",
        name: "#1",
      },
      {
        perfumeOrganId: "2",
        name: "#2",
      },
      {
        perfumeOrganId: "3",
        name: "#3",
      },
      {
        perfumeOrganId: "4",
        name: "#4",
      },
      {
        perfumeOrganId: "5",
        name: "#5",
      },
      {
        perfumeOrganId: "6",
        name: "#6",
      },
      {
        perfumeOrganId: "7",
        name: "#7",
      },
      {
        perfumeOrganId: "8",
        name: "#8",
      },
      {
        perfumeOrganId: "9",
        name: "#9",
      },
      {
        perfumeOrganId: "10",
        name: "#10",
      },
      {
        perfumeOrganId: "11",
        name: "#11",
      },
      {
        perfumeOrganId: "12",
        name: "#12",
      },
    ],
  },
];

const allAvailablePerfumeOrgans: PerfumeOrgan[] = availableWorkshopLocations.flatMap(
  (workswhop) => workswhop.perfumeOrgans,
);

export function adaptPerfumeWorkshopLocation(workshopLocationId: string): WorkshopLocation | null {
  return (
    availableWorkshopLocations.find(
      (workswhop) => workswhop.workshopLocationId === workshopLocationId,
    ) ?? null
  );
}

export function adaptPerfumeOrgan(perfumeOrganId: string): PerfumeOrgan | null {
  return allAvailablePerfumeOrgans.find((organ) => organ.perfumeOrganId === perfumeOrganId) ?? null;
}
