import { initializeApp, type FirebaseApp } from "firebase/app";
import {
  connectFirestoreEmulator,
  initializeFirestore,
  persistentLocalCache,
  persistentMultipleTabManager,
  type Firestore,
} from "firebase/firestore";
import {
  connectAuthEmulator,
  getAuth,
  // getRedirectResult,
  GoogleAuthProvider,
} from "firebase/auth";

export class FirebaseWrapper {
  app: FirebaseApp;
  firestore: Firestore;
  googleAuthProvider: GoogleAuthProvider;

  // Deactivate analytics because they interact with addBlock and prevent site loading in dev.
  // analytics: Analytics;

  constructor(connectEmulators: boolean) {
    const firebaseConfig = {
      apiKey: "AIzaSyCCJoKCeMPdg4SogvFv0dTge-qtLKtdj60",
      // authDomain: "fragrance-b6af8.firebaseapp.com",
      authDomain: "atelier-galimard.yespara.eu",
      projectId: "fragrance-b6af8",
      storageBucket: "fragrance-b6af8.appspot.com",
      messagingSenderId: "97200659935",
      appId: "1:97200659935:web:4685b2a8f3f4b4ec01b86a",
      measurementId: "G-0WEBR3EHGW",
    };

    // Initialize Firebase
    this.app = initializeApp(firebaseConfig);

    this.firestore = initializeFirestore(this.app, {
      localCache: persistentLocalCache({ tabManager: persistentMultipleTabManager() }),
    });

    // this.analytics = getAnalytics(this.app);
    if (connectEmulators) {
      connectAuthEmulator(this.auth, "http://localhost:9099");
      connectFirestoreEmulator(this.firestore, "localhost", 8080);
    }

    // To apply the default browser preference instead of explicitly setting it.
    this.auth.useDeviceLanguage();

    this.googleAuthProvider = new GoogleAuthProvider();

    // getRedirectResult(this.auth).then((userCredential) => {
    //     if (userCredential === null) {
    //       return
    //     }

    //     // The signed-in user info.
    //     console.log(
    //       `User ${userCredential.user.email} credentials from ${userCredential.providerId} with ${userCredential.operationType}`
    //     );
    // }).catch((error : unknown) => {
    //   if (error instanceof Error) {
    //     // Handle Errors here.
    //     // const errorCode = error.code;
    //     // const errorMessage = error.message;
    //     // The email of the user's account used.
    //     // const email = error.customData.email;
    //     // The AuthCredential type that was used.
    //     // const credential = GoogleAuthProvider.credentialFromError(error);
    //   }
    //   throw error
    // });
  }

  get auth() {
    return getAuth(this.app);
  }
}
